import React from "react"
import { graphql } from "gatsby"
import RelatedArticleCard from "../QuantumTouch/RelatedArticleCard"
import {
  StyledRelatedResources,
  RelatedResourceList,
  RelatedResourceItem,
} from "../../styledComponents/styledRelatedResources"
import { LatestResourceList, StyledLatestResources } from "../../styledComponents/styledLatestResources"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"


const RelatedQtArticles = ({ data, location }) => {
  return (
    <>
      <StyledLatestResources>
        <h2>Related articles</h2>
        <RelatedResourceList>
          {data.nodes.map(post => (
            <RelatedResourceItem key={post.id}>
              <RelatedArticleCard
                title={post.title}
                slug={post.slug}
              />
            </RelatedResourceItem>
          ))}

          <RelatedResourceItem key='richardgordon-video'>
          <h3>An interview with Richard Gordon the founder of Quantum-Touch</h3>
          <Link to="/quantum-touch/an-interview-with-richard-gordon-founder-of-quantum-touch/?autoplay=true"><StaticImage src="https://www.karinagrant.co.uk/images/karina-grant-interviews-richard-gordon-placeholder.jpg"></StaticImage></Link>
          </RelatedResourceItem>

        </RelatedResourceList>

        </StyledLatestResources>

    </>
  )
}

export default RelatedQtArticles


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allWpInformation(filter: {tags: {nodes: {elemMatch: {slug: {eq: "quantum-touch"}}}}, slug: {eq: "what-will-i-learn-on-a-quantum-touch-level-ii-workshop"}}) {
      nodes {
        title
        excerpt
        content
        uri
        slug
        date
        dateGmt
      }
    }
  }
`