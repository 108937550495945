import React from "react"
import {
  StyledRelatedArticleCard,
  ResourceHeader
} from "./StyledRelatedArticleCard"
import { Link } from "gatsby"
import parse from "html-react-parser"

const RelatedArticleCard = props => {
  const { title, slug  } = props

  return (
    <StyledRelatedArticleCard
      itemScope
      itemType="http://schema.org/Article"
    >
      <Link to={`/quantum-touch/`+ slug} itemProp="url">
        <ResourceHeader itemProp="headline">{parse(title)}</ResourceHeader>
      </Link>
    </StyledRelatedArticleCard>
  );
}

export default RelatedArticleCard
